function openHistoryChange(phraseCreatedBy, phraseId, phraseEditId) {
  openModal('#detai_change_history_modal');
  if (phraseId) {
    loadPhraseId(phraseId)
  } else if (phraseCreatedBy && phraseEditId) {
    loadPhraseEditId(phraseEditId, phraseCreatedBy)
  } else if  (phraseEditId) {
    loadPhraseEditId(phraseEditId, null)
  }
}

function loadPhraseId(phraseId) {
$.ajax({
    type: 'get',
    url: `/admin/api/phrases/${phraseId}`,
  }).done(response => {
    var html = '';
    $.each(response, function(i, item) {
      html += '<tr><td>' + item.id + '</td><td>' + item.category_groups_name + '</td><td>' 
      + item.categories_name + '</td> <td>' + `${item.value > 0 ? 'ポジ' : 'ネガ' }` + 
      '</td> <td>' + `${item.word1 ? item.word1 : '' }` + '</td><td>' + `${item.word2 ? item.word2 : '' }` + 
      '</td> <td>' + `${item.word3 ? item.word3 : '' }` + '</td><td>' + item.status_phrases +
      '</td> <td>' + `${item.source_id ? item.source_id : '' }` + 
      '</td><td>' + `${item.destination_id ? item.destination_id : '' }` +
      '</td><td>' + convertDate(item.updated_at) + '</td></tr>';
    });
    $('.js-detai_change_history').html(html);
  })
}

function loadPhraseEditId(phraseEditId, phrase_id) {
  $.ajax({
    type: 'get',
    url: `/admin/api/phrases_edit_status/${phraseEditId}`,
    data: {phrase_id}
  }).done(response => {
    var html = '';
      $.each(response.reverse(), function(i, item) {
        html += '<tr><td>' + `${item.phrases_id ? item.phrases_id : '' }` + '</td><td>' + item.category_groups_name + '</td><td>' 
        + item.categories_name + '</td> <td>' + `${item.value > 0 ? 'ポジ' : 'ネガ' }` + 
        '</td> <td>' + `${item.word1 ? item.word1 : '' }` + '</td><td>' + `${item.word2 ? item.word2 : '' }` + 
        '</td> <td>' + `${item.word3 ? item.word3 : '' }` + '</td><td>' + item.status_phrases +
        '</td> <td>' + `${item.source_id || ''  }` + 
        '</td><td>' + `${item.destination_edit_id ? item.destination_edit_id : '' }` +
        '</td><td>' + convertDate(item.updated_at) + '</td></tr>';
      });
    $('.js-detai_change_history').html(html);
  })
}

function convertDate(date) {
  const formatDate = new Date(date)
  return `${formatDate.getFullYear()}.${formatDate.getMonth() + 1}.${formatDate.getDate()}`
}

window.openHistoryChange = openHistoryChange;