function openCategoryCriteriaModal() {
  openModal('#category_criteria_modal');
}
function enableEditCategoryCriteria() {
  
  let value = $('.content-criteria').attr('contenteditable');  
  if (value == 'false') {
    $('.content-criteria').attr('contenteditable','true');
    $( ".content-criteria" ).css( "border", "2px solid #ddd" );
    $("#btnEdit").html("保存");
  }
  else {
    $('.content-criteria').attr('contenteditable','false');
    $( ".content-criteria" ).css( "border", "2px solid white" );
    $("#btnEdit").html("編集");
    const criteria = $('.content-criteria')
    const data = []
    for (let index = 0; index < criteria.length; index++) {
      data.push({id: index + 1, memo: criteria[index].innerText})
    }
    $.ajax({
      type: 'put',
      url: `/admin/update_memo`,
      data:  {data} ,
    })
  }
}
window.openCategoryCriteriaModal = openCategoryCriteriaModal;
window.enableEditCategoryCriteria = enableEditCategoryCriteria;