<template lang="pug">
.wrapper
  .left(@click="store.prevMonth()")
    div <
  .right(@click="store.nextMonth()")
    div >
  .months
    .month(v-for="(month,i) in months" :style="{width: monthWidths[i] + 'px'}") {{ month.format("YYYY年M月") }}
</template>

<script>
import { store } from './todo_chart.store';
import { fourMonths, sundaysInMonth } from './todo_chart.utils';
import { gridWidth } from './todo_chart.config';

export default {
  data() {
    return { store };
  },
  computed: {
    months() {
      return fourMonths(store.state.beginningMonth);
    },
    monthWidths() {
      return this.months.map(month => sundaysInMonth(month).length * gridWidth);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import './common'
.wrapper
  position relative

.left, .right
  position absolute
  background-color #3a4652
  width 14px
  height 14px
  font-size 10px
  color white
  border-radius 50%
  cursor pointer
  text-align center
  top 7px

.left
  left 2px
  > div
    margin-left -1px

.right
  right 3px

.months
  display flex
  border-left 1px solid lightgray
  margin-left -1px
  height gridHeight

.month
  text-align center
  font-size 10px
  line-height gridHeight
  border-right 1px solid lightgray
  box-sizing border-box
  &:first-child
    padding-left 10px
  &:last-child
    padding-right 10px
</style>
