$(() => {
  $('#js-review-delete').on('ajax:success', event => {
    const reviewId = event.target.href.split('/')[5];
    $(`.review-item-${reviewId}`).remove();
    closeModal('#review-delete-modal');
  });
  $('#js-review-delete').on('ajax:error', () => alert('エラーが発生しました'));
});

function openReviewDeleteModal(event) {
  const data = JSON.parse(event.target.dataset.reviewDelete);
  const modal = $('#review-delete-modal');
  modal.find('.review-user').text(data.userInfo);
  modal.find('.review-ttl').text(data.title);
  modal.find('.review-text').text(data.content);
  $('#js-review-delete').attr('href', `/api/reviews/${data.reviewId}`);
  openModal('#review-delete-modal');
}

window.openReviewDeleteModal = openReviewDeleteModal;
