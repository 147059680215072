<template lang="pug">
.chunks
  .title(v-if='titleChunks.length')
    span.chunk(v-for="chunk in titleChunks" :class="chunkClass(chunk, 'title')" @click="selectChunk(chunk, 'title')") {{ chunk.surface }}
  .content
    span.chunk(v-for="chunk in contentChunks" :class="chunkClass(chunk, 'content')" @click="selectChunk(chunk, 'content')") {{ chunk.surface }}

</template>
<script>
import axios from '../utils/axios_with_init';

export default {
  props: ['contentChunks', 'titleChunks', 'selectedChunks', 'selectedContext'],
  computed: {
    chunks() {
      if (!this.selectedContext) {
        return this.contentChunks.length < this.titleChunks.length
          ? this.titleChunks
          : this.contentChunks;
      }
      return this.isContentSelected ? this.contentChunks : this.titleChunks;
    },
    allChunkIndexes() {
      return this.chunks.map(chunk => chunk.index);
    },
    selectableChunks() {
      if (this.selectedChunks.length === 0) {
        return this.allChunkIndexes;
      } else if (this.selectedChunks.length >= 3) {
        return [];
      }
      return this.selectedChunks.map(i => this.chunkRelatedIndexes(i)).flat();
    },
    isSelectedPhraseMutated() {
      return Boolean(
        this.selectedPhrase && this.selectedPhrase.mutationPhraseId
      );
    },
    isContentSelected() {
      return this.selectedContext === 'content';
    },
  },
  methods: {
    chunkRelatedIndexes(i) {
      return [
        ...this.chunks
          .filter(chunk => chunk.link === i)
          .map(chunk => chunk.index),
        this.chunks[i].link,
      ];
    },
    chunkClass(chunk, context) {
      if (!this.selectedContext) {
        return 'selectableChunk';
      }
      if (this.selectedContext !== context) {
        return;
      }
      if (this.isSelectedPhraseMutated) {
        return { selectedChunk: this.selectedChunks.includes(chunk.index) };
      }
      return {
        selectedChunk: this.selectedChunks.includes(chunk.index),
        selectableChunk: this.selectableChunks.includes(chunk.index),
      };
    },
    selectChunk(chunk, context) {
      if (this.isSelectedPhraseMutated) {
        return;
      }
      if (this.selectedChunks.includes(chunk.index)) {
        const updatedChunks = this.selectedChunks
          .slice()
          .splice(this.selectedChunks.indexOf(chunk.index), 1);
        this.$emit('update:selectedChunks', updatedChunks);
        if (!this.selectedChunks.length) {
          this.$emit('update:selectedContext', undefined);
        }
      } else if (this.selectableChunks.includes(chunk.index)) {
        this.$emit(
          'update:selectedChunks',
          this.selectedChunks.concat(chunk.index)
        );
        this.$emit('update:selectedContext', context);
      }
    },
  },
};
</script>

<style scoped lang="stylus">
.selectableChunk
  background-color lightgray
  cursor pointer

.selectedChunk
  background-color yellow

.chunks
  border solid 1px gray
  margin 10px 0
  padding 5px

.chunk
  margin 1px 5px
  display inline-block

.title
  margin-bottom 20px
</style>
