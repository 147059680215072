const categoryGroupName = [
  {
    id:1,
    group_id: 1,
    group_name: '運営方法(ｾｯﾃｨﾝｸﾞ)'
  },
  {
    id:2,
    group_id: 1,
    group_name: 'ﾒﾝﾃﾅﾝｽ'
  },
  {
    id:3,
    group_id: 1,
    group_name: 'ﾚｲｱｳﾄ'
  },
  {
    id:4,
    group_id: 1,
    group_name: 'その他'
  },
  {
    id:5,
    group_id: 2,
    group_name:  '料金/ﾎﾟｲﾝﾄ等'
  },
  {
    id:6,
    group_id: 2,
    group_name:  'その他(SC/精算機等)'
  },
  {
    id:7,
    group_id: 3,
    group_name: '運営方法'
  },
  {
    id:8,
    group_id: 3,
    group_name: 'ﾒﾆｭｰ/価格'
  },
  {
    id:9,
    group_id: 3,
    group_name: '味/量', 
  },
  {
    id:10,
    group_id: 3,
    group_name: '提供ｽﾋﾟｰﾄﾞ'
  },
  {
    id:11,
    group_id: 3,
    group_name: 'その他'
  },
  {
    id:12,
    group_id: 4,
    group_name: '運営方法'
  },
  {
    id:13,
    group_id: 4,
    group_name: 'ﾌﾟﾚｰ時間/休憩/遅れ'
  },
  {
    id:14,
    group_id: 4,
    group_name: '乗り入れ'
  },
  {
    id:15,
    group_id: 4,
    group_name: 'カートの状態'
  },
  {
    id:16,
    group_id: 4,
    group_name:  'その他'
  },
  {
    id:17,
    group_id: 5,
    group_name: '運営方法'
  },
  {
    id:18,
    group_id: 5,
    group_name: '練習場'
  },
  {
    id:19,
    group_id: 5,
    group_name: 'ｶｰﾄ道路'
  },
  {
    id:20,
    group_id: 5,
    group_name: '風呂/ﾄｲﾚ/ﾛｯｶｰ'
  },
  {
    id:21,
    group_id: 5,
    group_name: 'その他'
  },
  {
    id:22,
    group_id: 6,
    group_name: 'ｺｰｽ'
  },
  {
    id:23,
    group_id: 6,
    group_name: 'ﾌﾛﾝﾄ'
  },
  {
    id:24,
    group_id: 6,
    group_name: 'ｷｬﾃﾞｨ'
  },
  {
    id:25,
    group_id: 6,
    group_name: 'ﾗｳﾝﾄﾞ管理'
  },
  {
    id:26,
    group_id: 6,
    group_name: 'ﾚｽﾄﾗﾝ'
  },
  {
    id:27,
    group_id: 6,
    group_name: '営繕'
  },
  {
    id:28,
    group_id: 6,
    group_name: '部署不明'
  },
  {
    id: 29,
    group_id: 7,
    group_name: 'ｺｽﾊﾟ'
  },
  {
    id: 30,
    group_id: 7,
    group_name: 'ﾘﾋﾟｰﾄ'
  },
  {
    id: 31,
    group_id: 7,
    group_name: 'お勧め'
  },
  {
    id: 32,
    group_id: 7,
    group_name: '満足/不満'
  },
  {
    id:33,
    group_id: 8,
    group_name: 'ｱｸｾｽ'
  },
  {
    id:34,
    group_id: 8,
    group_name: 'ﾏﾅｰ/客層'
  },
  {
    id:35,
    group_id: 8,
    group_name: 'その他'
  },
]

export default categoryGroupName;
