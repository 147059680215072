<template lang="pug">
div.wrapper
  .left
    .name {{ todo.courseName }}
    .category {{ todo.category }}
    .qsch {{ todo.qsch }}
    .title {{ todo.title }}
    .responsible {{ todo.responsible }}
    .duedate {{ todo.dueDate }}
  .right(:style="{width: width + 'px'}")
    todo-calendar-bar(:todo='todo')
</template>

<script>
import { store } from './todo_chart.store';
import TodoCalendarBar from './todo_calendar_bar';
import { gridWidth } from './todo_chart.config';
import { sundaysInFourMonths } from './todo_chart.utils';

export default {
  components: { TodoCalendarBar },
  props: ['todo'],
  data() {
    return { store };
  },
  computed: {
    width() {
      return sundaysInFourMonths(store.state.beginningMonth).length * gridWidth;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import './common'
.wrapper
  display flex
  height gridHeight
  border-bottom 1px solid gray
  width 100%

.left
  padding-top 2px
  display flex
  font-size 12px
  line-height 25px
  border-right 1px solid lightgray
  min-width 0
  flex-grow 1
  > div
    text-overflow ellipsis
    white-space nowrap
    overflow hidden
    text-align center
    padding 0 2px
    flex-grow 1
    &.name
      flex-basis 100px
      text-align left
    &.category
      flex-basis 60px
    &.qsch
      flex-basis 40px
    &.title
      flex-basis 160px
      text-align left
    &.responsible
      flex-basis 40px
    &.duedate
      flex-basis 99px


.right
  height gridHeight
</style>
