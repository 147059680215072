<template lang="pug">
div
  table-header
  .todosWrapper
    canvas.belowTodoCanvas(ref='belowTodoCanvas' :height='height' :width='width')
    .todos
      .todo(v-for="todo in visibleTodos" :key='todo.id' @click='openModal(todo)')
        todo-line(:todo='todo')
    canvas.aboveTodoCanvas(ref='aboveTodoCanvas' :height='height' :width='width')
</template>

<script>
import moment from 'moment';
import * as _ from 'lodash';
import TableHeader from './table_header';
import TodoLine from './todo_line';
import { store } from './todo_chart.store';
import { sundaysInFourMonths } from './todo_chart.utils';
import { gridWidth, gridHeight } from './todo_chart.config';

export default {
  components: { TableHeader, TodoLine },
  data() {
    return { todos: gon.todos_for_chart, store };
  },
  computed: {
    visibleTodos() {
      return this.store.visibleTodos();
    },
    sundays() {
      return sundaysInFourMonths(this.store.state.beginningMonth);
    },
    height() {
      return (this.visibleTodos.length + 1) * gridHeight;
    },
    width() {
      return this.sundays.length * gridWidth;
    },
  },
  watch: {
    sundays() {
      this.$nextTick(() => {
        this.drawToCanvases();
      });
    },
  },
  created() {
    this.store.state.todos = this.todos;
  },
  mounted() {
    this.drawToCanvases();
  },
  methods: {
    openModal(todo) {
      set_todo_params(
        $('#todo_form'),
        JSON.parse(todo.todoModalData),
        todo.todoModalReviewData
      );

      openModal('#todo-modal');
    },
    drawToCanvases() {
      this.drawToAboveTodoCanvas(this.$refs.aboveTodoCanvas);
      this.drawToBelowTodoCanvas(this.$refs.belowTodoCanvas);
    },
    drawToAboveTodoCanvas(ref) {
      const ctx = ref.getContext('2d');
      ctx.clearRect(0, 0, this.width, this.height);
      if (this.sundays[0] > moment().add(1, 'week')) {
        return;
      }
      const currentSundayIndex = this.sundays.findIndex(
        sunday => sunday > moment()
      );

      ctx.lineWidth = 1;
      ctx.strokeStyle = '#0066ff';
      ctx.beginPath();
      ctx.moveTo(gridWidth * currentSundayIndex + gridWidth / 2, 0);
      ctx.lineTo(
        gridWidth * currentSundayIndex + gridWidth / 2,
        this.height - gridHeight
      );
      ctx.stroke();
    },
    drawToBelowTodoCanvas(ref) {
      const ctx = ref.getContext('2d');
      ctx.clearRect(0, 0, this.width, this.height + gridHeight);
      ctx.lineWidth = 1;
      ctx.strokeStyle = 'lightgray';
      _.times(this.sundays.length, i => {
        ctx.beginPath();
        ctx.moveTo(gridWidth * (i + 1) - 0.5, 1);
        ctx.lineTo(gridWidth * (i + 1) - 0.5, this.height + gridHeight);
        ctx.stroke();
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import './common'

.todosWrapper
  position relative
  display flex
  width 100%
.todos
  border-top 1px solid gray
  position absolute
  width 100%
.aboveTodoCanvas, .belowTodoCanvas
  position absolute
  pointer-events none
  right 0
.aboveTodoCanvas
  top 0
.belowTodoCanvas
  top -1 * gridHeight
</style>
