<template lang="pug">
.analyzed_phrase
  .title {{ title }}
  .category-group {{ categoryGroup }}
  .category {{ category }}
  .posinega
    div(:class='value > 0 ? "posi" : "nega"') {{ posinega }} 
  .surfaces {{ surfaces }} {{ isPendingDelete }}
  .buttons
    button(@click="$emit('select')") 修正・削除
</template>

<script>
export default {
  props: ['title', 'analyzedPhrase', 'contentChunks', 'titleChunks'],
  computed: {
    chunks() {
      return this.analyzedPhrase.context === 'content'
        ? this.contentChunks
        : this.titleChunks;
    },
    categoryGroup() {
      return gon.category_group_names[this.analyzedPhrase.categoryGroupId];
    },
    category() {
      return gon.category_names[this.analyzedPhrase.categoryId];
    },
    value() {
      const analyzedPhrase = this.analyzedPhrase;
      return analyzedPhrase.mutationValue
        ? analyzedPhrase.mutationValue * analyzedPhrase.phraseValue
        : analyzedPhrase.phraseValue;
    },
    posinega() {
      if (this.value > 0) {
        return 'ポジ';
      } else if (this.value < 0) {
        return 'ネガ';
      } else {
        return '中立';
      }
    },
    surfaces() {
      const analyzedPhrase = this.analyzedPhrase;
      if (analyzedPhrase.phraseChunk1Index == null) {
        return analyzedPhrase.phraseWords.join(' ');
      }
      return [
        analyzedPhrase.phraseChunk1Index,
        analyzedPhrase.phraseChunk2Index,
        analyzedPhrase.phraseChunk3Index,
        analyzedPhrase.mutationPhraseChunk1Index,
        analyzedPhrase.mutationPhraseChunk2Index,
        analyzedPhrase.mutationPhraseChunk3Index,
      ]
        .filter(v => v != null)
        .map(i => this.chunks[i].surface)
        .join(' ');
    },
    isPendingDelete() {
      if(['approval_pending_delete', 'disable_approval_pending', 'disable_approved'].includes(this.analyzedPhrase.status)) return '【削除】'
      return
    },
  },
};
</script>

<style scoped lang="stylus">
.analyzed_phrase
  display flex
  padding 2px 0
  align-items center
  &:not(:last-child)
    border-bottom 1px solid gray
  > div:not(:last-child)
    margin-right: 6px

button
  background-color #007bff
  color #fff
  padding 0.5rem 2rem
  font-size 1.4rem
  line-height 1
  font-weight bold
  border-radius 4px
  margin-left auto
  white-space nowrap

.title
  font-weight bold
  white-space nowrap
  width 140px

.category-group
  width 60px
  @media (min-width: 1200px) { width: 110px }

.category
  width 120px

.posinega
  width 50px

.posi, .nega
  width 100%
  text-align center

.posi
  background-color #76D6FF

.nega
  background-color #FF7E79

.surfaces
  flex 1 0
</style>
