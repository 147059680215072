<template lang="pug">
.chars
  template(v-if='titleChunks.length')
    .title
      span.char(v-for="char, i in titleChars" :class="charClass(i, 'title')" @mousedown="onMouseDown($event, i, 'title')" @mousemove="onMouseMove($event, i, 'title')" ) {{ char }}
  .content
    span.char(v-for="char, i in contentChars" :class="charClass(i, 'content')" @mousedown="onMouseDown($event, i, 'content')" @mousemove="onMouseMove($event, i, 'content')") {{ char }}

</template>
<script>
import axios from '../utils/axios_with_init';

function range(num1, num2) {
  if (num1 < num2) {
    return range(num2, num1);
  }
  return [...Array(num1 + 1).keys()].slice(num2);
}

export default {
  props: ['contentChunks', 'titleChunks', 'selectedChars', 'selectedContext'],
  data: () => ({
    initialChar: undefined,
  }),
  computed: {
    titleChars() {
      return this.titleChunks.map(chunk => chunk.surface).join('');
    },
    contentChars() {
      return this.contentChunks.map(chunk => chunk.surface).join('');
    },
  },
  methods: {
    onMouseDown(e, index, context) {
      this.initialChar = index;
      this.$emit('update:selectedContext', context);
      this.$emit('update:selectedChars', [index]);
    },
    onMouseMove(e, index, context) {
      if (!e.buttons || this.selectedContext != context) {
        return;
      }
      this.$emit('update:selectedChars', range(index, this.initialChar));
    },
    charClass(index, context) {
      if (this.selectedContext !== context) {
        return;
      }
      return {
        selectedChar: this.selectedChars.includes(index),
      };
    },
  },
};
</script>

<style scoped lang="stylus">
.chars
  border solid 1px gray
  margin 10px 0
  padding 5px 10px
  -ms-user-select none
  -moz-user-select -moz-none
  -khtml-user-select none
  -webkit-user-select none
  user-select none

.char
  background-color lightgray
  display inline-block
  cursor pointer
  margin 1px 0

.selectedChar
  background-color yellow

.title
  margin-bottom 20px
</style>
