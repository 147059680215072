$(function() {
  $('#new_todo_search_form').on('submit', () => {
    const errors = getDateErrors();
    if (errors.length) {
      alert(errors.join('\n'));
      return false;
    }
    return true;
  });

  $('input[name="user[role]').on('change', function($event) {
    initTargetManagement($event.target.value);
    $('#user_course_group_area_id')
      .val('')
      .change();
    set_select_options(
      '#user_course_group_id',
      Object.values(gon.course_group_names_in_area).flat()
    );
    set_select_options(
      '#user_course_id',
      Object.values(gon.course_names_in_area).flat()
    );
  });

  $('#user_course_group_area_id').on('change', function($event) {
    const select = $event.target.value;
    const options = select
      ? gon.course_group_names_in_area[$event.target.value]
      : Object.values(gon.course_group_names_in_area).flat();

    const options2 = select
      ? gon.course_names_in_area[$event.target.value]
      : Object.values(gon.course_names_in_area).flat();

    set_select_options('#user_course_group_id', options);
    set_select_options('#user_course_id', options2);
  });

  $('#user_course_group_id').on('change', function($event) {
    const select = $event.target.value;

    const options = select
      ? gon.course_names_in_group[$event.target.value]
      : Object.values(gon.course_names_in_group).flat();
    set_select_options('#user_course_id', options);
  });

  $('#new_user').on('ajax:success', () => {
    alert('編集した内容を保存して更新します。');
    closeModal('#modal_user_form');
    location.reload();
  });
  $('#new_user').on('ajax:error', e => {
    $('#new_user .error').removeClass('error');
    e.detail[0].keys.forEach(key => {
      if (['course_group_area', 'course_group'].includes(key)) {
        $('#new_user')
          .find(`#user_${key}_id`)
          .addClass('error');
      } else if (key === 'course') {
        $('#select2-user_course_id-container').addClass('error');
      } else {
        $('#new_user')
          .find(`#user_${key}`)
          .addClass('error');
      }
    });
    alert(e.detail[0].errors.join('\n'));
  });
});

function dateParamNames() {
  return $('.search-body input[data-datepicker="true"]')
    .toArray()
    .filter(el => !$(el).closest('[style*="display: none;"]').length)
    .map(el => el.name.split('[')[1].split(']')[0]);
}

function isDateParamVaild(name) {
  return /^\d{4}-\d{2}-\d{2}$/.test($(`#todo_search_form_${name}`).val());
}

function getDateErrors() {
  if (dateParamNames().length) {
    const errors = dateParamNames()
      .map(name => {
        if (!isDateParamVaild(name)) {
          const paramText = $(`label[for="todo_search_form_${name}"]`).text();
          return `「${paramText}」の選択を正しく行ってください。`;
        }
      })
      .filter(v => v);
    if (errors.length) {
      return errors;
    }
    const begin = $('#todo_search_form_published_at_begin').val();
    const end = $('#todo_search_form_published_at_end').val();
    if (begin > end) {
      return ['「いつまで」は「いつから」よりも後に設定してください。'];
    }
  }
  return [];
}

function openModalUser(user_id = null) {
  openModal('#modal_user_form');
  init_user_form(user_id);
}
function initTargetManagement(value) {
  $('.role_user').addClass('disabled');
  if (value === 'area_admin') {
    $('.role_user:lt(1)').removeClass('disabled');
  } else if (value === 'group_admin') {
    $('.role_user:lt(2)').removeClass('disabled');
  } else if (value === 'course_admin') {
    $('.role_user').removeClass('disabled');
  } else {
    return;
  }
}

function init_user_form(user_id) {
  $('#new_user .error').removeClass('error');
  $('.role_user').removeClass('disabled');
  $('#new_user').trigger('reset');
  $('#select2-user_course_id-container').html('全て');

  if (user_id) {
    $('.modal-header__ttl').html('編集');
    $.ajax({
      type: 'GET',
      url: `/users/${user_id}`,
      data: { user_id: user_id },
    }).done(response => {
      $('#user_course_group_area_id').val(response.course_group_area_id);
      $('#user_course_group_id').val(response.course_group_id);
      if (response.course_id) {
        $('#user_course_id').val(response.course_id);
        const course_names = gon.course_names.filter(
          x => x[0] == response.course_id
        )[0][1];
        $('#select2-user_course_id-container').html(course_names);
      }
      $(`input[name="user[email]"]`).val(response.email);
      $(`input[name="user[name]"]`).val(response.name);
      $(`input[name="user[password]"]`).val(null);
      $(`input[name="user[password_confirm]"]`).val(null);
      $('input[name="user[role]')
        .filter(`[value="${response.role}"]`)
        .prop('checked', true);
      $('input[name="user[bulk_replyable]"]')
        .filter(`[value="${response.bulk_replyable}"]`)
        .prop('checked', true);
      $('input[name="user[review_replyable]"]')
        .filter(`[value="${response.review_replyable}"]`)
        .prop('checked', true);
      $('.password_user').hide();
      initTargetManagement(response.role);
    });
    $('#new_user').attr({
      action: `/users/${user_id}/update`,
    });
  } else {
    $('.modal-header__ttl').html('新規登録');
    $('.password_user').show();
    $('input[name="user[role]')
      .filter(`[value=course_admin]`)
      .prop('checked', true);
    $('#new_user').attr({
      action: '/users/create_new',
    });
  }
}

function handleConfirmDeleteUser(user_id) {
  if (confirm('削除してもよろしいですか？')) {
    $.ajax({
      type: 'PATCH',
      url: `/users/${user_id}/delete`,
    })
      .done(() => {
        location.reload();
      })
      .fail(() => {});
  }
}

function set_select_options(el, options) {
  const select = $(el);
  const current_value = select.val();
  select.html('');
  select.append(
    $('<option>')
      .val('')
      .text('全て')
  );

  options.forEach(([key, value]) =>
    select.append(
      $('<option>')
        .val(key)
        .text(value)
    )
  );
}

window.openModalUser = openModalUser;
window.handleConfirmDeleteUser = handleConfirmDeleteUser;
