import moment from 'moment';
import * as _ from 'lodash';

export var store = {
  debug: true,
  state: {
    beginningMonth: moment()
      .startOf('month')
      .subtract('1', 'month'),
    todos: [],
  },
  visibleTodos() {
    return this.state.todos.filter(
      todo =>
        moment(todo.registeredDate) <=
          this.state.beginningMonth
            .clone()
            .add(4, 'months')
            .startOf('week') &&
        moment(todo.dueDate) > this.state.beginningMonth.clone().startOf('week')
    );
  },
  fourMonths() {
    return _.times(4, i => this.state.beginningMonth.clone().add(i, 'months'));
  },
  weeks() {
    const weeks = [];
    const fourMonthsLater = this.state.beginningMonth.clone().add(4, 'months');
    let sunday = this.state.beginningMonth.clone().startOf('week');
    while (sunday <= fourMonthsLater) {
      weeks.push(sunday.clone());
      sunday.add('1', 'week');
    }
    return weeks;
  },
  nextMonth() {
    this.state.beginningMonth.add('1', 'month');
    this._detectChange();
  },
  prevMonth() {
    this.state.beginningMonth.subtract('1', 'month');
    this._detectChange();
  },
  _detectChange() {
    this.state = { ...this.state };
  },
};
