function closeModal(modal) {
  $(modal).fadeOut(200);
  $('.modal-overlay').fadeOut('fast', function() {
    $('html, body').removeClass('lock');
    $('.modal-overlay').remove();
  });
}
function openModal(modal) {
  $('html, body').addClass('lock');

  // オーバーレイをフェードイン
  if (!$('.modal-overlay').length) {
    $('body').append('<div class="modal-overlay"></div>');
    $('.modal-overlay').fadeIn(200);
  }

  // モーダルコンテンツの表示位置を設定
  modalResize();

  // モーダルコンテンツフェードイン
  $(modal).fadeIn(200);

  // モーダルを閉じる
  $(document).on('click', function(e) {
    // モーダルのスクリーン(modal-content)内だが、モーダルコンテンツ外をクリックしたら閉じる
    if (
      !$(e.target).closest('.js-modal-open').length &&
      $(e.target).closest('.modal-content').length &&
      !$(e.target).closest('.modal-container').length
    ) {
      modalClose();
    }
  });
  $(document).on('click', '.js-modal-close', function() {
    modalClose();
  });

  function modalClose() {
    closeModal(modal);
  }

  // リサイズしたら表示位置を再取得
  $(window).on('resize', function() {
    modalResize();
  });

  // モーダルコンテンツの表示位置を設定する関数
  function modalResize() {
    // ウィンドウの横幅、高さを取得
    var w = $(window).width();
    var h = $(window).height();

    // モーダルコンテンツの横幅、高さを取得
    var mw = $(modal).outerWidth(true);
    var mh = $(modal).outerHeight(true);

    var x;
    var y;

    // モーダルコンテンツの表示位置を設定
    if (mh > h && mw > w) {
      $(modal).css({ left: 0 + 'px', top: 0 + 'px' });
    } else if (mh > h && mw < w) {
      x = (w - mw) / 2;
      $(modal).css({ left: x + 'px', top: 0 + 'px' });
    } else if (mh < h && mw > w) {
      y = (h - mh) / 2;
      $(modal).css({ left: 0 + 'px', top: y + 'px' });
    } else {
      x = (w - mw) / 2;
      y = (h - mh) / 2;
      $(modal).css({ left: x + 'px', top: y + 'px' });
    }
  }
}

$(document).on('click', '.js-modal-confirm-cancel', function() {
  alert('編集した内容は保存されません。閉じてよろしいですか？');
  $('.js-modal-close').trigger('click');
});

function openFormModal() {
  openModal('#' + $(this).attr('data-target'));
}

function openConfirmModal(message) {
  const modal = '#confirm-modal';
  $(modal)
    .find('.message')
    .text(message);
  openModal(modal);
  $(modal)
    .find('button[type=submit]')
    .off();
  return new Promise(resolve =>
    $(modal)
      .find('button[type=submit]')
      .on('click', resolve)
  );
}

window.openModal = openModal;
window.closeModal = closeModal;
window.openFormModal = openFormModal;
window.openConfirmModal = openConfirmModal;
