import moment from 'moment';
import * as _ from 'lodash';

export function sundaysInMonth(monthMoment) {
  const weeks = [];
  const oneMonthLater = monthMoment.clone().add(1, 'month');
  let sunday = monthMoment
    .clone()
    .add(6, 'days')
    .startOf('week');
  while (sunday < oneMonthLater) {
    weeks.push(sunday.clone());
    sunday.add('1', 'week');
  }
  return weeks;
}

export function fourMonths(monthMoment) {
  return _.times(4, i => monthMoment.clone().add(i, 'months'));
}

export function sundaysInFourMonths(monthMoment) {
  return fourMonths(monthMoment)
    .map(sundaysInMonth)
    .flat();
}

export function inRange(value, begin_at, end_at) {
  const valueMoment = moment(value);
  return moment(begin_at) <= valueMoment && valueMoment <= moment(end_at);
}
