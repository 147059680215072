<template lang="pug">
table.category-selector
  tr
    th ポジネガ
    td
      label(:class="{selected: positive}")
        input(:checked="positive" type="radio" name="value" value="50" @change="$emit('update:phraseValue', $event.target.value)")
        | ポジ
      label(:class="{selected: negative}")
        input(:checked="negative" type="radio" name="value" value="-50" @change="$emit('update:phraseValue', $event.target.value)")
        | ネガ
  tr
    th カテゴリ
    td
      table.category-row
        tr(v-for="(groupName, groupId) in categoryGroupNames")
          td.category_group_name {{ groupName }}
          td.category_name
            .category(v-for="category in categoryNamesGroupByCategoryId.filter(x => x.group_id == groupId)")
              label(:class="{selected: isCurrentCategory(category)}")
                input(:value="category.id" @change="$emit('update:categoryId', $event.target.value)" type="radio" name="category" :checked="isCurrentCategory(category)")
                | {{ category.group_name }}
</template>

<script>
import categoryGroupName from '../utils/const_category_group';
export default {
  props: ['categoryId', 'phraseValue'],
  data: () => ({
    categoryGroupNames: gon.category_group_names,
    categoryNamesGroupByCategoryId: categoryGroupName,
  }),
  computed: {
    positive() {
      return this.phraseValue == 50;
    },
    negative() {
      return this.phraseValue == -50;
    },
  },
  methods: {
    isCurrentCategory(category) {
      return category.id == this.categoryId;
    },
  },
};
</script>

<style lang="stylus" scoped>
table.category-selector
  border-bottom 1px solid gray
  margin-bottom 2px
  > tr
    min-height 30px
    > th
      width 80px
      text-align left
      padding-top 3px
    > td
    > th
      vertical-align top

table.category-row
  td.category_group_name
    padding-top 3px
    vertical-align top
    min-width: 110px
  td.category_name
    display: flex
    flex-wrap: wrap
    .category
      width 170px
      label
        margin: 3px 0

tr:not(:last-child)
  border-bottom 1px solid gray

label
  display inline-block
  margin-right 10px
  margin-top 3px
  margin-bottom 3px
  background-color: lightgray
  &.selected
    background-color: yellow
</style>
