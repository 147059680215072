$(function() {
  set_up_course_params();
  set_up_validator();
});

function set_up_validator() {
  $('.export form').off('submit');
  $('.export form').on('submit', function() {
    if (!validatePublishedAt()) {
      return false;
    }
    return true;
  });
}

function isDateParamVaild(value) {
  return /^\d{4}-\d{2}-\d{2}$/.test(value);
}

function validatePublishedAt() {
  if (!$('#published_at_begin').length) {
    return true;
  }

  const published_at_begin_value = $('#published_at_begin').val();
  const published_at_end_value = $('#published_at_end').val();
  if (!isDateParamVaild(published_at_begin_value)) {
    alert('「開始年月日」の選択を正しく行ってください。');
    return false;
  }
  if (!isDateParamVaild(published_at_end_value)) {
    alert('「終了年月日」の選択を正しく行ってください。');
    return false;
  }
  if (
    Date.parse(published_at_end_value) - Date.parse(published_at_begin_value) >=
    24 * 3600 * 31 * 1000
  ) {
    alert('対象期間は１ヶ月以内に設定してください。');
    return false;
  }
  return true;
}

/***
 * function set_up_course_param()
 *
 * Implement select tag hierarchy (Area > Group)
 */
function set_up_course_params() {
  const owner_el = $('select[name=course_owner]');
  const area_el = $('select[name=course_group_area_id]');
  const group_el = $('select[name=course_group_id]');
  const course_el = $('select[name=course_id]');

  if (!group_el.length) {
    return;
  }

  function reload_params() {
    const area_id = area_el.val();
    const group_id = group_el.val();
    const owner = owner_el.val();

    set_select_options(group_el, group_options(area_id));
    set_select_options(
      course_el,
      course_options_for_owner(area_id, group_id, owner)
    );
  }

  function group_options(area_id) {
    if (area_id) {
      return gon.course_group_names_in_area[area_id];
    } else {
      return gon.course_group_names;
    }
  }

  function course_options_for_owner(area_id, group_id, owner) {
    if (owner === 'all') {
      return course_options(area_id, group_id);
    }
    const course_names_for_owner = gon.course_names_for_owner[owner];
    return course_options(area_id, group_id).filter(course_name =>
      course_names_for_owner.some(
        course_name_for_owner =>
          course_name_for_owner[0] === course_name[0] &&
          course_name_for_owner[1] === course_name[1]
      )
    );
  }

  function course_options(area_id, group_id) {
    if (group_id) {
      return gon.course_names_in_group[group_id];
    } else if (area_id) {
      return gon.course_names_in_area[area_id];
    } else {
      return gon.course_names;
    }
  }

  owner_el.change(reload_params);
  area_el.change(reload_params);
  group_el.change(reload_params);
  reload_params();
}

// Utility for set_up_*_course_params()
function set_select_options(el, options) {
  const select = $(el);
  const current_value = select.val();
  select.html('');
  select.append(
    $('<option>')
      .val('')
      .text('全て')
  );

  if (!options || !options.length) return;

  options.forEach(([key, value]) =>
    select.append(
      $('<option>')
        .val(key)
        .text(value)
    )
  );
  if (options.some(option => option[0] == current_value)) {
    select.val(current_value);
  }
}
