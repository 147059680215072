/**
 * review_reply.js
 * クチコミ一覧画面で返信するダイアログを操作する
 * a. 返信完了時に表示を更新する
 * b. 返信ボタンクリック時にフォームを調整する
 *   1. 過去のデータ消去
 *   2. 返信編集ユーザーかそうでないかによって切り替える
 *   3. 返信削除機能のリンクを修正
 * c. 定型文による操作を行う
 */
const moment = require('moment');

$(function() {
  // a. 返信完了後に表示を更新する
  $('#review_reply_form').on('ajax:success', result => {
    closeModal($('#review-reply-modal'));
    alert('クチコミの返信を更新しました');
  });
  $('#review_reply_form').on('ajax:error', () => {
    alert('書き込みに失敗しました');
  });

  // c. 定型文による操作を行う
  $('#reply_template_title').select2();
  $('#reply_template_title').change(e => {
    setCode(e.target.value);
    setReplyContentByInputValue();
  });
  $('#review_reply_form_reply_template_code').keypress(e => {
    if (e.which == 13) {
      e.preventDefault();
      setReplyTitleByInputValue();
      return false;
    }
  });
  $('.js-reply-template-button').click(() => setReplyTitleByInputValue());
});

// b. 返信ボタンクリック時にフォームを調整する
function setReviewReplyParams(form, params) {
  initReviewReplyForm(form, params);

  // inputに値を入れる
  Object.keys(params).forEach(key => {
    const input = form.find(`[name="review_reply_form[${key}]"]`);
    const value = input.attr('type') !== 'radio' ? params[key] : [params[key]];
    if (key == 'reply_text_confirm_required') {
      input.prop('checked', value);
    } else {
      input.val(value);
    }
  });

  // 本文がなく、タイトルに値が入っていなければ、'ゴルフ場からの回答'にする
  const commentEl = form.find('[name="review_reply_form[comment]"]');
  const titleEl = form.find('[name="review_reply_form[title]"]');
  if (!commentEl.val() && !titleEl.val()) titleEl.val('ゴルフ場からの回答');

  // 本文がなく、担当者に値が入っていなければ、'運営担当者'にする
  const staffEl = form.find('[name="review_reply_form[staff_name]"]');
  if (!commentEl.val() && !staffEl.val()) staffEl.val('運営担当者');

  // モーダル内に、編集中の元クチコミ本文を表示する
  form
    .closest('#review-reply-modal')
    .find('.js-reply-review-content')
    .html(params.review_content || '投稿本文なし');

  // モーダル内に、編集中の元クチコミ件名を表示する
  form
    .closest('#review-reply-modal')
    .find('.js-reply-review-title')
    .html(params.review_title || '投稿タイトルなし');
}

function initReviewReplyForm(form, params) {
  const isDraft =
    params.has_draft_response_content ||
    params.force_draft ||
    !gon.user_review_replyable;
  form[0].reset();
  form.attr(
    'action',
    `/api/reviews/${params.id}/${isDraft ? 'draft_reply' : 'reply'}`
  );
  form.find('.form-btn-submit[type=submit]').text(isDraft ? '保存' : '返信');

  $('#review_reply_form_reply_template_code').val('');
  $('#reply_template_title').val('');
  $('#reply_template_title').trigger('change');

  if ('title' in params) {
    $('.js-reply-modal-title').show();
    $('.js-reply-modal-staff-name').show();
  } else {
    $('.js-reply-modal-title').hide();
    $('.js-reply-modal-staff-name').hide();
  }

  // 削除ボタンの扱い
  if (params.has_draft_response_content) {
    // 下書きがある場合は、下書きを削除
    $('#js-review-reply-delete').attr(
      'href',
      `/api/reviews/${params.id}/delete_draft_reply`
    );
    $('#review-reply-delete-modal .modal-header__ttl').text(
      '投稿への返信の下書きを削除します。よろしいですか？'
    );
    form.find('.form-btn-delete').text('この下書きを削除');
    form.find('.form-btn-delete').show();
  } else if (!isDraft && params.comment && gon.user_review_replyable) {
    // 下書き編集ダイアログでなく、返信済みで、権限があれば返信を削除
    $('#js-review-reply-delete').attr(
      'href',
      `/api/reviews/${params.id}/delete_reply`
    );
    $('#review-reply-delete-modal .modal-header__ttl').text(
      '投稿への返信を削除します。よろしいですか？'
    );
    form.find('.form-btn-delete').text('この返信を削除');
    form.find('.form-btn-delete').show();
  } else {
    // 権限がないか、未返信の場合はボタンを非表示
    form.find('.form-btn-delete').hide();
  }
}

// c. 定型文による操作を行う
function setCode(code) {
  $('#review_reply_form_reply_template_code').val(code);
  $('#review_reply_form_reply_template_code').change();
}

function setReplyTitleByInputValue() {
  const code = $('#review_reply_form_reply_template_code').val();
  const body = gon.reply_template_bodies[code];
  if (body == null) return alert('該当のコードがありません');
  $('#reply_template_title').val(code);
  $('#reply_template_title').trigger('change');
}

function setReplyContentByInputValue() {
  setReplyContentByCode($('#review_reply_form_reply_template_code').val());
}

function setReplyContentByCode(code) {
  if (!code) return;
  const body = gon.reply_template_bodies[code];
  if (body == null) return alert('該当のコードがありません');
  setReplyContent(gon.reply_template_bodies[code]);
}

function setReplyContent(body) {
  $('#review_reply_form_comment').val(body);
}

// b. 返信ボタンクリック時にフォームを調整する
function openReplyModal(event) {
  const reviewReplyData = JSON.parse(event.target.dataset.reviewReply);
  setReviewReplyParams($('#review_reply_form'), reviewReplyData);
  openModal('#review-reply-modal');
}

window.openReplyModal = openReplyModal;
