<template lang="pug">
.review
  a.review-btn-action-required(href='#' :class='review.actionRequired ? "active" : "inactive"' @click='toggleActionRequired()') 要対応
  a.review-btn-reply-required(href='#' :class='review.replyRequired ? "active" : "inactive"' @click='toggleReplyRequired()') 要返信
  table#review-tbl
    tr
      th 事業所名
      td {{ review.courseName }}
    tr
      th 投稿者情報
      td
        | {{ review.userInfo }} {{ review.publishedDateJa }} {{ review.sitename }}
        br
        | {{ review.metaList.join(' ') }}
    tr
      th タイトル
      td.title
        span.chunk(v-for="char, i in review.title.split('')" :class="charClass(i, 'title')" ) {{ char }}
    tr
      th 本文
      td.content
        span.chunk(v-for="char, i in review.content.split('')" :class="charClass(i, 'content')") {{ char }}
  .analyzed_phrases
    analyzed-phrase(v-for="(analyzedPhrase, i) in review.analyzedPhrases" :key="analyzedPhrase.id" :analyzedPhrase='analyzedPhrase' :title='phraseName(i, false)' @select='setPhrase(i)' :contentChunks="review.contentChunks" :titleChunks="review.titleChunks")
  phrase-editor(:content-chunks="review.contentChunks" :title-chunks="review.titleChunks" :title="phraseEditorTitle" :selected-phrase="review.analyzedPhrases[selectedPhrase]" :review-id="review.reviewId" @cancel="unsetPhrase" @reload='$emit("reload")')
  .review-btn-wrapper
    a.review-btn-confirm-required(href='#' :class='review.phraseApproved ? "active" : "inactive"' @click='toggleApprovePhrase()') 確認
</template>

<script>
import PhraseEditor from "./phrase_editor";
import AnalyzedPhrase from "./analyzed_phrase";
import axios from "../utils/axios_with_init";

export default {
  components: { PhraseEditor, AnalyzedPhrase },
  props: ["review"],
  data: () => ({
    selectedPhrase: undefined,
  }),
  computed: {
    phraseEditorTitle() {
      if (this.selectedPhrase != undefined) {
        return this.phraseName(this.selectedPhrase, true);
      }
      return "［新規評価］入力";
    },
    phraseSelected() {
      let array = [];
      const idxSelected = [];
      this.review.analyzedPhrases
        .filter((e) => e.context === "content")
        .forEach((e) => {
          if (e.phraseChunk1Index != null) {
            array.push(this.review.contentChunks[e.phraseChunk1Index].surface);
          }
          if (e.phraseChunk2Index != null) {
            array.push(this.review.contentChunks[e.phraseChunk2Index].surface);
          }
          if (e.phraseChunk3Index != null) {
            array.push(this.review.contentChunks[e.phraseChunk3Index].surface);
          }
          if (
            e.phraseChunk1Index == null &&
            e.phraseChunk2Index == null &&
            e.phraseChunk3Index == null
          ) {
            array = array.concat(e.phraseWords);
          }
        });
      const content = this.review.content;

      array.forEach((element) => {
        for (
          let index = content.indexOf(element.replace("\r", ""));
          index <
          content.indexOf(element.replace("\r", "")) +
            element.replace("\r", "").length;
          index++
        ) {
          if (content.indexOf(element.replace("\r", "")) == -1) break;
          idxSelected.push(index);
        }
        if (
          content.replace(/(\r\n|\n|\r)/gm, "") ==
          element.replace(/(\r\n|\n|\r)/gm, "")
        ) {
          for (let idx = 0; idx < content.length; idx++) {
            idxSelected.push(idx);
          }
        }
      });
      const uniqueidxSelected = idxSelected.filter((element, index) => {
        return idxSelected.indexOf(element) === index;
      });
      return uniqueidxSelected;
    },
    titleSelected() {
      let array = [];
      const idxSelected = [];
      this.review.analyzedPhrases
        .filter((e) => e.context === "title")
        .forEach((e) => {
          if (e.phraseChunk1Index != null) {
            array.push(this.review.titleChunks[e.phraseChunk1Index].surface);
          }
          if (e.phraseChunk2Index != null) {
            array.push(this.review.titleChunks[e.phraseChunk2Index].surface);
          }
          if (e.phraseChunk3Index != null) {
            array.push(this.review.titleChunks[e.phraseChunk3Index].surface);
          }
          if (
            e.phraseChunk1Index == null &&
            e.phraseChunk2Index == null &&
            e.phraseChunk3Index == null
          ) {
            array = array.concat(e.phraseWords);
          }
        });
      array.forEach((element) => {
        for (
          let index = this.review.title.indexOf(element.replace("\r", ""));
          index <
          this.review.title.indexOf(element.replace("\r", "")) +
            element.replace("\r", "").length;
          index++
        ) {
          idxSelected.push(index);
        }
      });
      const uniqueidxSelected = idxSelected.filter((element, index) => {
        return idxSelected.indexOf(element) === index;
      });
      return uniqueidxSelected;
    }
  },
  methods: {
    setPhrase(i) {
      this.selectedPhrase = i;
    },
    unsetPhrase() {
      this.selectedPhrase = undefined;
    },
    phraseName(i, isSelect) {
      const analyzedPhrase = this.review.analyzedPhrases[i];
      let text = "(システム評価)";
      if (analyzedPhrase?.status) text = "(承認待ち)";
      if (analyzedPhrase?.status == "disable_approval_pending" && isSelect)
        text = "(承認待ち)【削除】";
      if (
        ["approved", "replace_approved", "disable_approved"].includes(
          analyzedPhrase?.status
        )
      )
        text = "(承認済)";
      return `評価${i + 1}${text}`;
    },
    toggleApprovePhrase() {
      const phraseApproved = !this.review.phraseApproved;
      const params = { phrase_approved: phraseApproved };
      axios
        .put(`/admin/api/reviews/${this.review.reviewId}`, params)
        .then(() => (this.review.phraseApproved = phraseApproved));
    },
    toggleReplyRequired() {
      const replyRequired = !this.review.replyRequired;
      const params = { reply_required: replyRequired };
      const message = this.review.replyRequired
        ? "要返信指定を解除します"
        : "要返信指定を行います";
      openConfirmModal(message).then(() =>
        axios
          .put(`/admin/api/reviews/${this.review.reviewId}`, params)
          .then(() => (this.review.replyRequired = replyRequired))
      );
    },
    toggleActionRequired() {
      const actionRequired = !this.review.actionRequired;
      const params = { action_required: actionRequired };
      const message = this.review.actionRequired
        ? "要対応指定を解除します"
        : "要対応指定を行います";
      openConfirmModal(message).then(() =>
        axios
          .put(`/admin/api/reviews/${this.review.reviewId}`, params)
          .then(() => (this.review.actionRequired = actionRequired))
      );
    },
    charClass(index, context) {
      return {
        selectedChar:
          context === "title"
            ? this.titleSelected.includes(index)
            : this.phraseSelected.includes(index),
      };
    },
  },
};
</script>

<style scoped lang="stylus">
.review
  margin-bottom 30px
  background-color #E2F0D9
  border-radius 5px
  padding 10px
  position relative

th
  width 100px
  text-align left
  vertical-align top

th
td
  padding-bottom 10px

.content
  white-space pre-line

.analyzed_phrases
  background-color white
  padding 5px 10px

.review-btn-wrapper
  padding 10px 10px 0
  text-align right

.review-btn-confirm-required
  display inline-block

.review-btn-reply-required,
.review-btn-action-required
  position absolute
  width 100px
  padding 5px 10px 5px 40px

.review-btn-reply-required
  right 140px

.review-btn-action-required
  right 20px
.selectedChar
  background-color yellow
</style>
