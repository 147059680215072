<template lang="pug">
canvas(:width='width' :height='height')
</template>

<script>
import { store } from './todo_chart.store';
import moment from 'moment';
import { sundaysInFourMonths } from './todo_chart.utils';
import { gridWidth } from './todo_chart.config';
import { gridHeight } from './todo_chart.config';

export default {
  props: ['todo'],
  computed: {
    period() {
      return sundaysInFourMonths(store.state.beginningMonth).map(
        this.isDateIncluded.bind(this)
      );
    },
    color() {
      if (this.todo.status == '済') {
        return 'lightgray';
      }
      if (moment(this.todo.dueDate).endOf('day') >= moment()) {
        return '#7EC3FE';
      }
      return '#FF0000';
    },
    width() {
      return this.period.length * gridWidth;
    },
    height() {
      return gridHeight;
    },
  },
  watch: {
    period() {
      this.$nextTick(() => {
        this.draw();
      });
    },
  },
  mounted() {
    this.ctx = this.$el.getContext('2d');
    this.draw();
  },
  methods: {
    isDateIncluded(date) {
      return (
        date.clone().subtract('1', 'week') < moment(this.todo.dueDate) &&
        moment(this.todo.registeredDate) <= date
      );
    },
    draw() {
      this.ctx.clearRect(0, 0, this.width, gridWidth);
      this.ctx.fillStyle = this.color;
      this.ctx.lineTo(gridWidth, gridWidth);

      this.period.forEach((value, i) => {
        const baseX = i * gridWidth;
        if (value) {
          this.ctx.fillRect(baseX, 7, gridWidth, 15);
        }
      });
    },
  },
};
</script>
