function toggleActionRequired(event) {
  const dataParams = JSON.parse(event.target.dataset.toggleActionRequired);
  const message = dataParams.action_required
    ? '要対応指定を解除します'
    : '要対応指定を行います';
  openConfirmModal(message).then(function() {
    const formData = new FormData();
    formData.append('review[action_required]', !dataParams.action_required);
    Rails.ajax({
      type: 'put',
      url: `/api/reviews/${dataParams.review_id}`,
      data: formData,
    });
  });
}

function toggleReplyRequired(event) {
  const dataParams = JSON.parse(event.target.dataset.toggleReplyRequired);
  const message = dataParams.reply_required
    ? '要返信指定を解除します'
    : '要返信指定を行います';
  openConfirmModal(message).then(function() {
    const formData = new FormData();
    formData.append('review[reply_required]', !dataParams.reply_required);
    Rails.ajax({
      type: 'put',
      url: `/api/reviews/${dataParams.review_id}`,
      data: formData,
    });
  });
}

function toggleQschMarked(event) {
  const dataParams = JSON.parse(event.target.dataset.toggleQschMarked);
  const message = dataParams.qsch_marked
    ? 'QSCH指定を解除します'
    : 'QSCH指定を行います';
  openConfirmModal(message).then(function() {
    const formData = new FormData();
    formData.append(
      'review[qsch_marked_at]',
      dataParams.qsch_marked ? null : new Date().toISOString()
    );
    Rails.ajax({
      type: 'put',
      url: `/api/reviews/${dataParams.review_id}`,
      data: formData,
    });
  });
}

window.toggleActionRequired = toggleActionRequired;
window.toggleReplyRequired = toggleReplyRequired;
window.toggleQschMarked = toggleQschMarked;
