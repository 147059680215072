import Vue from 'vue';
import AdminReviews from '../vue_admin_reviews_evaluation/admin_reviews.vue';

document.addEventListener('DOMContentLoaded', () => {
  const adminReviewsEl = document.getElementById('vue-admin-reviews-evaluation');
  if (adminReviewsEl) {
    const app = new Vue({
      render: h =>
        h(AdminReviews, {
          props: {
            adminReviews: JSON.parse(adminReviewsEl.dataset.adminReviews),
          },
        }),
    }).$mount();
    adminReviewsEl.appendChild(app.$el);
  }
});
