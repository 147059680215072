$(function() {
  $('.csv_export_condition_form').on('ajax:success', () => {
    alert('文字列指定条件を更新しました'), location.reload();
  });
  $('.csv_export_condition_form').on('ajax:error', () => {
    alert('入力内容にエラーがあります。内容をご確認ください。');
  });
  $('.js-csv-export-condition-delete').on('ajax:success', () => {
    alert('文字列指定条件を削除しました'), location.reload();
  });
  $('.js-csv-export-condition-delete').on('ajax:error', e => {
    alert('文字列指定条件を削除できませんでした'), location.reload();
  });
});
