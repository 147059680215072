<template lang="pug">
.phrase_editor
  .title
    | {{ title || '[新規評価] 入力' }}
  .main
    .main-title(v-if='!this.selectedPhrase')
      label
        input(type="radio" value='phrase' v-model='phraseType' @change="clearSelection")
        | ワード登録（該当ワードを選択）
      label
        input(type="radio" value='char' v-model='phraseType' @change="clearSelection")
        | 文字列登録（該当文字列を選択）
    chunk-selector(v-if="phraseType == 'phrase'" :titleChunks='titleChunks' :contentChunks='contentChunks' :selectedChunks.sync='selectedChunks' :selectedContext.sync='selectedContext')
    char-selector(v-if="phraseType == 'char'" :titleChunks='titleChunks' :contentChunks='contentChunks' :selectedChars.sync='selectedChars' :selectedContext.sync='selectedContext')
    category-selector(:categoryId.sync='selectedCategoryId' :phraseValue.sync='phraseValue')
    .buttons
      template(v-if="selectedPhrase")
        button(@click="cancel") キャンセル
        button(@click="replace") この評価を修正する
        button(v-if="selectedPhrase.status === 'disable_approval_pending'" @click="undoDestroy") この評価削除を取り消す
        button(:class="selectedPhrase.status === 'disable_approved' && 'disabledBtn'")(v-else @click="destroy") この評価を削除する
      template(v-else="")
        button.gray(@click="cancel") 選択クリア
        button(@click="create") 評価を登録する
</template>

<script>
import axios from '../utils/axios_with_init';
import ChunkSelector from './chunk_selector';
import CharSelector from './char_selector';
import CategorySelector from './category_selector';

export default {
  components: { ChunkSelector, CharSelector, CategorySelector },
  props: [
    'contentChunks',
    'titleChunks',
    'title',
    'selectedPhrase',
    'reviewId',
  ],
  data: () => ({
    phraseValue: undefined,
    phraseType: 'phrase',
    selectedCategoryId: undefined,
    selectedChunks: [],
    selectedChars: [],
    selectedContext: undefined,
  }),
  computed: {
    chunks() {
      if (!this.selectedContext) {
        return this.contentChunks.length < this.titleChunks.length
          ? this.titleChunks
          : this.contentChunks;
      }
      return this.isContentSelected ? this.contentChunks : this.titleChunks;
    },
    chars() {
      return this.chunks.map(chunk => chunk.surface).join('');
    },
    isContentSelected() {
      return this.selectedContext === 'content';
    },
  },
  watch: {
    selectedPhrase() {
      if (!this.selectedPhrase) {
        return;
      }
      this.clear();
      this.selectedContext = this.selectedPhrase.context;
      this.phraseValue = this.selectedPhrase.phraseValue;
      this.selectedCategoryId = this.selectedPhrase.categoryId;
      if (this.selectedPhrase.phraseWords.length == 1) {
        this.phraseType = 'char';
        const word1 = this.selectedPhrase.phraseWords[0];
        const firstCharIndex = this.chars.search(word1);
        this.selectedChars = [...Array(word1.length).keys()].map(
          i => i + firstCharIndex
        );
      } else {
        this.phraseType = 'phrase';
        this.selectedChunks = [
          this.selectedPhrase.phraseChunk1Index,
          this.selectedPhrase.phraseChunk2Index,
          this.selectedPhrase.phraseChunk3Index,
          this.selectedPhrase.mutationPhraseChunk1Index,
          this.selectedPhrase.mutationPhraseChunk2Index,
          this.selectedPhrase.mutationPhraseChunk3Index,
        ].filter(value => typeof value === 'number');
      }
    },
  },
  methods: {
    clearSelection() {
      this.selectedChunks = [];
      this.selectedChars = [];
      this.selectedContext = undefined;
    },
    clear() {
      this.phraseType = 'phrase';
      this.phraseValue = undefined;
      this.selectedCategoryId = undefined;
      this.selectedChunks = [];
      this.selectedChars = [];
      this.selectedContext = undefined;
    },
    cancel() {
      this.clear();
      this.$emit('cancel');
    },
    reload() {
      this.clear();
      this.$emit('cancel');
      this.$emit('reload');
    },
    destroy() {
      if (confirm('削除してもよろしいですか？')) {
        this.deleteRequest().then(this.reload.bind(this));
      }
    },
    undoDestroy() {
      this.undoDeleteRequest().then(this.reload.bind(this));
    },
    replace() {
      if (!this.validate()) {
        return;
      }
      if (confirm('修正してもよろしいですか？')) {
        this.updateAndReload();
      }
    },
    create() {
      if (!this.validate()) {
        return;
      }
      if (confirm('登録してもよろしいですか？')) {
        this.createAndReload();
      }
    },
    deleteRequest() {
      if (this.selectedPhrase.phraseEditId) {     
        const statusPhrases = this.selectedPhrase.status === 'approval_pending' ? 'delete' : 3
        return axios.delete(
            `/admin/api/phrases_edit_status/${this.selectedPhrase.phraseEditId}`,
            { params: { status: statusPhrases } }
          );
      } else {
        return axios.post(
            '/admin/api/destroy_old_phrases',
            { phraseId: this.selectedPhrase.phraseId, review_id: this.reviewId, status: 3, context: this.selectedContext }
        );
      }
    },
    undoDeleteRequest() {
      return axios.post(
          '/admin/api/undo_destroy_phrases',
          { phraseId: this.selectedPhrase.phraseId, phraseEditId: this.selectedPhrase.phraseEditId }
      );
    },
    createRequest() {
      const params = {
        category_id: this.selectedCategoryId,
        value: this.phraseValue,
        review_id: this.reviewId,
        context: this.selectedContext,
        status: 0
      };
      if (this.phraseType == 'phrase') {
        if (this.selectedChunks.length === 2) {
          params.type_phrases = 'PairPhrase';
          params.word1 = this.chunks[this.selectedChunks[0]].surface;
          params.word2 = this.chunks[this.selectedChunks[1]].surface;
          params.phrase_chunk1_index = this.selectedChunks[0];
          params.phrase_chunk2_index = this.selectedChunks[1];
        } else if (this.selectedChunks.length === 3) {
          params.type_phrases = 'TrioPhrase';
          params.word1 = this.chunks[this.selectedChunks[0]].surface;
          params.word2 = this.chunks[this.selectedChunks[1]].surface;
          params.word3 = this.chunks[this.selectedChunks[2]].surface;
          params.phrase_chunk1_index = this.selectedChunks[0];
          params.phrase_chunk2_index = this.selectedChunks[1];
          params.phrase_chunk3_index = this.selectedChunks[2];
        }
      } else if (this.phraseType == 'char') {
        params.type_phrases = 'SinglePhrase';
        params.word1 = this.selectedChars
          .map(char => this.chars.charAt(char))
          .join('');
      }
      return axios.post('/admin/api/phrases_edit_status', params);
    },
    updateRequest() {
      const params = {
        category_id: this.selectedCategoryId,
        value: this.phraseValue,
        review_id: this.reviewId,
        context: this.selectedContext,
        phraseId: this.selectedPhrase?.phraseId || null,
      };
      if (this.phraseType == 'phrase') {
        if (this.selectedChunks.length === 2) {
          params.type_phrases = 'PairPhrase';
          params.word1 = this.chunks[this.selectedChunks[0]].surface;
          params.word2 = this.chunks[this.selectedChunks[1]].surface;
          params.phrase_chunk1_index = this.selectedChunks[0];
          params.phrase_chunk2_index = this.selectedChunks[1];
        } else if (this.selectedChunks.length === 3) {
          params.type_phrases = 'TrioPhrase';
          params.word1 = this.chunks[this.selectedChunks[0]].surface;
          params.word2 = this.chunks[this.selectedChunks[1]].surface;
          params.word3 = this.chunks[this.selectedChunks[2]].surface;
          params.phrase_chunk1_index = this.selectedChunks[0];
          params.phrase_chunk2_index = this.selectedChunks[1];
          params.phrase_chunk3_index = this.selectedChunks[2];
        }
      } else if (this.phraseType == 'char') {
        params.type_phrases = 'SinglePhrase';
        params.word1 = this.selectedChars
          .map(char => this.chars.charAt(char))
          .join('');
      }
      if (['approval_pending', 'approval_pending_delete', 'approved' ].includes(this.selectedPhrase.status)) {
        params.status = 0;
        return axios.patch(`/admin/api/phrases_edit_status/${this.selectedPhrase.phraseEditId}`, params);
      } else if (['replace_approval_pending', 'replace_approved', 'remove_approved', 'disable_approval_pending', 'disable_approved' ].includes(this.selectedPhrase.status)) {
        params.status = 7;
        return axios.patch(`/admin/api/phrases_edit_status/${this.selectedPhrase.phraseEditId}`, params);
      } else {
        params.status = 5;
        return axios.post('/admin/api/update_old_phrases', params);
      }
    },
    createAndReload() {
      this.createRequest().then(this.reload.bind(this));
    },
    updateAndReload() {
      this.updateRequest().then(this.reload.bind(this));
    },
    validate() {
      if (!this.selectedCategoryId) {
        alert('カテゴリを選択してください。');
        return;
      } else if (!this.phraseValue) {
        alert('ポジネガを選択してください。');
        return;
      } else if (
        this.phraseType == 'phrase' &&
        ![2, 3].includes(this.selectedChunks.length)
      ) {
        alert('ワード登録では2語または3語を選択してください。');
        return;
      } else if (this.phraseType == 'char' && !this.selectedChars.length) {
        alert('文字列では文字列を1文字以上選択してください。');
        return;
      }
      return true;
    },
  },
};
</script>

<style scoped lang="stylus">
.title
  background-color #548235
  color white
  font-weight bold
  padding 10px
  font-size 16px
  margin-top 10px

.main
  background-color white
  padding 10px

.main-title
  font-weight bold
  font-size 16px
  label
    margin-right 5px

.buttons
  text-align right

button
  background-color #007bff
  color #fff
  padding 1rem
  font-size 1.4rem
  line-height 1
  font-weight bold
  border-radius 4px
  margin-left auto
  &.gray
    background-color #AFABAB

button:not(:last-child)
  margin-right: 5px;
.disabledBtn
  background-color #AFABAB
  pointer-events: none
</style>
