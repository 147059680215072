/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import './modal';
import './form';
import './todo';
import './review_reply';
import './review_toggle_flags';
import './function';
import './vue_admin_reviews';
import './vue_todo_chart';
import './bulk_reply';
import './export';
import './review_delete_modal';
import './review_reply_delete_modal';
import './csv_export_condition';
import './vue_admin_reviews_evaluation';
import './category_criteria_modal';
import './detail_change_history_modal';
import './modal_user_form';