const moment = require('moment');

$(function() {
  $('#bulk_reply_form').on('ajax:success', result => {
    alert('送信が完了しました。');
    location.reload();
  });
  // $('#bulk_reply_form').on('ajax:error', () => {
  //   alert('送信が失敗した返信があります。');
  //   location.reload();
  // });
});

function bulkReply() {
  const count = $('#bulk_reply_form input[type=checkbox]:checked').length;
  if (!count) return alert('選択してください。');
  openConfirmModal(
    `チェックを入れた返信${count}件を送信します。よろしいですか？`
  ).then(() => $('#bulk_reply_form').submit());
}

function selectAllReply() {
  $('#bulk_reply_form input[type=checkbox]').prop('checked', true);
}

function unselectAllReply() {
  $('#bulk_reply_form input[type=checkbox]').prop('checked', false);
}

window.bulkReply = bulkReply;
window.selectAllReply = selectAllReply;
window.unselectAllReply = unselectAllReply;
