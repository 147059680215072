<template lang="pug">
div
  .table_header
    .table-header-left
      .row-titles
        .row-title.name 事業所名
        .row-title.category カテゴリ
        .row-title.qsch QSCH
        .row-title.title タイトル
        .row-title.responsible 担当者
        .row-title.duedate 期日
    .table-header-right
      table-calendar.table-calendar
      .dates
        .date(v-for='sunday in sundays' :class='{active: isCurrentSunday(sunday)}') {{ sunday.format('D') }}
</template>

<script>
import moment from 'moment';
import { store } from './todo_chart.store';
import TableCalendar from './table_calendar';
import { fourMonths, sundaysInMonth } from './todo_chart.utils';

export default {
  components: { TableCalendar },
  data() {
    return { store };
  },
  computed: {
    months: () => fourMonths(store.state.beginningMonth),
    sundaysInMonths() {
      return this.months.map(sundaysInMonth);
    },
    sundays() {
      return this.sundaysInMonths.flat();
    },
  },
  methods: {
    isCurrentSunday(date) {
      return date.clone().subtract('1', 'week') < moment() && moment() <= date;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import './common'

.table_header
  display flex

.table-header-left
  margin-top gridHeight + 2px
  padding-top 5px
  width leftTableWidth
  height gridHeight
  color white
  background-color #3a4652
  border-radius 3px 3px 0 0
  flex-grow 1
.table-header-right
  border-top 1px solid gray

.row-titles
  display flex

.row-title
  font-size 12px
  line-height 20px
  text-align center
  flex-grow 1
  &.name
    flex-basis 100px
  &.category
    flex-basis 60px
  &.qsch
    flex-basis 40px
  &.title
    flex-basis 160px
  &.responsible
    flex-basis 40px
  &.duedate
    flex-basis 99px

.dates
  border-top 1px solid gray
  display flex

.date
  width gridWidth
  height gridHeight
  font-size 12px
  line-height gridHeight
  text-align center

  &.active
    background-color #0066ff
    border-radius 50%
    width 21px
    height 21px
    line-height 21px
    margin 4px 2px 0
    color white
</style>
