import Vue from 'vue';
import TodoChart from '../vue_todo_chart/todo_chart.vue';

document.addEventListener('DOMContentLoaded', () => {
  const vueEl = document.getElementById('vue-todo-chart');
  if (vueEl) {
    const app = new Vue({
      render: h => h(TodoChart),
    }).$mount();
    vueEl.appendChild(app.$el);
  }
});
