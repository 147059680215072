$(function() {
  if ($('.js-todo-calendar').length) {
    const dates = $.unique(
      JSON.parse($('.js-todo-calendar')[0].dataset.todoDates)
    );
    const eventData = dates.map(date => ({
      date,
      classname:
        date < new Date().toISOString().substring(0, 10)
          ? 'event-before'
          : 'event-after',
    }));
    $('.js-todo-calendar').zabuto_calendar({
      language: 'jp',
      cell_border: true,
      today: true,
      data: eventData,
      action: function() {
        const date_el = $('#' + this.id);
        const date = date_el.data('date');
        const url = `#{todos_path}?todo_search_form[course_id]=#{@course.id}&todo_search_form[due_date_period]=specific_date&todo_search_form[due_date]=${date}`;
        if (date_el.data('hasEvent')) {
          window.location = url;
        }
      },
    });
  }
});

function moveToReviews(base_path, params) {
  const additionalParams = `&${params.join('&')}`;
  location.href = base_path + additionalParams;
}

function reviewFilterFormValueParam(key, value) {
  return `f%5B${key}%5D=${value}`;
}

function reviewFilterFormListParam(key, value) {
  return `f%5B${key}%5D%5B%5D=${value}`;
}

window.moveToReviews = moveToReviews;
window.reviewFilterFormValueParam = reviewFilterFormValueParam;
window.reviewFilterFormListParam = reviewFilterFormListParam;
