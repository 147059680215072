$(function() {
  $('#todo_form').on('ajax:success', () => {
    alert('編集した内容を保存して更新します。'), location.reload();
  });
  $('#todo_form').on('ajax:error', e => {
    $('#todo_form .error').removeClass('error');
    Object.keys(e.detail[0]).forEach(key => {
        if (key === 'created_user') {
          $('#select2-todo_created_user_id-container').addClass('error');
        } else {
          $('#todo_form')
            .find(`#todo_${key}`)
            .addClass('error')
        }
      }
    );
    alert('入力内容にエラーがあります。内容をご確認ください。');
  });
});

function set_todo_params(form, params, review_html = 'なし') {
  _init_todo_form(form, params.id);
  
  let dataTodoQsch = $(".todo-btn-new").attr("data-todo-qsch") ? JSON.parse($(".todo-btn-new").attr("data-todo-qsch")) : null
 
  const assignment_type = form.find(`[name="todo[assignment_type]"]`);
  if (params?.qsch || dataTodoQsch) {
    assignment_type.val(['qsch']);
  } else if (params?.crisis_management) {
    assignment_type.val(['crisis_management']);
  } else {
    assignment_type.val(['no_apply']);
  }
  assignment_type.trigger('change');

  $('input[name="todo[other_flags][]"]').each((index, checkbox) => {
    if (params.other_flags.includes(index + 1)) checkbox.checked = true;
  });

  Object.keys(params).forEach(key => {
    const input = form.find(`[name="todo[${key}]"]`);
    const value = input.attr('type') !== 'radio' ? params[key] : [params[key]];
    input.val(value);
    input.trigger('change');
  });

  form.find('.todo-review').html(review_html);
}

function _init_todo_form(form, todo_id) {
  form[0].reset();
  form.find('select').trigger('change');
  form.find('[name="todo[review_id]"]').val('');
  $('#todo_form .error').removeClass('error');
  if (todo_id) {
    // Update
    form.find('[name=_method]').val('patch');
    form.attr('action', `/todos/${todo_id}`);
  } else {
    // Create
    form.find('[name=_method]').val('post');
    form.attr('action', '/todos');
  }
}

function openTodoModal(event) {
  const datasetEl = event.target.dataset.todo
    ? event.target
    : $(event.target).closest('[data-todo]')[0];
  const todoData = JSON.parse(datasetEl.dataset.todo);
  const todoReviewData = datasetEl.dataset.todoReview;
  set_todo_params($('#todo_form'), todoData, todoReviewData);
  openModal('#todo-modal');
}

function openTodoListModal(event) {
  const reviewId = event.target.dataset.todoListReviewId;
  const todoQsch = event.target.dataset.todoQsch;
  $(".todo-btn-new").attr({
    "data-todo-qsch": !!todoQsch,
  });
  loadTodoList(reviewId);
  openModal('#todo-list-modal');
}

function loadTodoList(review_id) {
  $.ajax({
    type: 'get',
    url: '/api/todos',
    data: { review_id },
  }).done(html => {
    // TODO: #todo-list-modal全とっかえでもいいかも
    $('.js-modal-todo-list').html(html);

    const firstDataTodo = $('.js-modal-todo-list')
      .find('[data-todo]')
      .data('todo');

    const newDataTodo = Object.assign(
      {},
      $('#todo-list-modal')
        .find('.todo-btn-new')
        .first()
        .data('todo'),
      { review_id, course_id: firstDataTodo.course_id }
    );

    $('#todo-list-modal').find(
      '.todo-btn-new'
    )[0].dataset.todo = JSON.stringify(newDataTodo);

    const firstDataTodoReview = $('.js-modal-todo-list')
      .find('[data-todo-review]')
      .data('todoReview');
    $('#todo-list-modal').find(
      '.todo-btn-new'
    )[0].dataset.todoReview = firstDataTodoReview;

    $('#todo-list-modal')
      .find('[data-todo]')
      .click(() => $('#todo-list-modal').fadeOut(200));
  });
}

window.set_todo_params = set_todo_params;
window.openTodoModal = openTodoModal;
window.openTodoListModal = openTodoListModal;
