<template lang="pug">
div
  review(v-for="(review, i) in reviews" :key="review.id" :review="review" @reload="reload(i)")
</template>

<script>
import axios from '../utils/axios_with_init';
import Review from './review';

export default {
  components: { Review },
  props: ['adminReviews'],
  data() {
    return { 
      reviews: [],
      currentAdmin: {}
     };
  },
  created() {
    this.reviews = this.adminReviews;
    const obj = localStorage.getItem("currentAdmin")
    if (obj) {
      this.currentAdmin = JSON.parse(localStorage.getItem("currentAdmin"));
      this.handleRelogin()
    }else {
      this.fetchCurrentAdmin()
    }
  },
  methods: {
    reload(i) {
      axios.get(`/admin/api/reviews/${this.reviews[i].reviewId}`).then(res => {
        this.reviews.splice(i, 1, res.data);
      });
    },
    fetchCurrentAdmin() {
      axios.get('/admin/current_admin').then(response => {
        localStorage.setItem('currentAdmin', JSON.stringify(response.data))
        this.handleRelogin()
      })
      .catch(error => {
        console.log(error);
      });
    },
    handleRelogin() {
      axios.post('/admin/re_login', { adminId: this.currentAdmin.id })
      .catch(error => {
        console.log(error);
      });
    }
  },
};
</script>
